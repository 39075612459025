@import "~styles/variables";
@import "~styles/helpers";

.ResetLicenseForm {

  .heading {

    .license-key {
      font-weight: 600;
    }
  }

  .bottom {
    margin-top: 10px;
    font-size: 14px;
    color: $text-color-secondary;

    span {
      font-weight: 600;
    }
  }
}