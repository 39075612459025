@import '~styles/variables';
@import '~styles/helpers';

.Page {
  height: calc(100vh - #{$Header-height});
  height: 100%;
  margin-top: $Header-height;
  padding: 0 20px 0 0;
  overflow-y: auto;
  display: flex;

  .Page-wrapper {
    width: 100%;
  }

  .Page-header {
    padding: 20px 30px;
    box-sizing: border-box;

    &.noPaddingBottom {
      padding-bottom: 0;

      h1 {
        margin-bottom: 0;
      }
    }

    .Page-breadcrumb {
      font-size: 14px;
    }
  }

  .Page-top {
    height: 20px;
  }

  .Page-title {
    color: $text-color-primary;
    font-size: 30px;
    font-weight: 500;
    margin: 15px 0 10px;
    display: flex;
    align-items: center;

    .title-tag {
      margin-left: 30px;
      font-size: 12px;
      border-width: 1px;
      border-style: solid;
      background: rgba($red, .25);
      border-color: rgba($red, .35);
      color: darken($red,30%);
      padding: 2px 12px;
    }
  }

  .Page-description {
    color: $text-color-secondary;
    font-size: 14px;
  }

  .Page-content {
    padding: 0 20px 50px 30px;
    @include animate(fadeInPage, 100ms, ease-in, 0);
  }

}

@include keyframe(fadeInPage) {
  from { opacity: 0; }
  to { opacity: 1; }
}