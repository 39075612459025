@import "~styles/variables";
@import "~styles/helpers";

.ResetPassword {
  @include flexMiddleCenter;

  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-image: url('/img/ls.jpg');
  background-size: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;

  .form-container {
    background-color: $white;
    padding: 20px 50px;
    border-radius: 3px;
    position: relative;
    max-width: 470px;
    box-sizing: border-box;

    .login-form {
      fieldset {
        border: 0;

        &:disabled {
          opacity: 0.5;
        }
      }
      .row {
        display: flex;
        flex-direction: column;
        margin: 0px 20px;

        p {
          text-align: center;
          font-size: 15px;
          margin: 0;
          color: $text-color-primary;
          font-weight: 500;
        }

        input {
          max-width: unset;
          width: 100%;
        }

        &.title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 16px;
          text-align: center;
        }

        &.logo-cont {
          @include flexMiddleCenter;
          margin-bottom: 10px;

          .company-name {
            margin: 20px 0;
            font-size: 18px;
            font-weight: 600;
          }
        }

        &.btn-cont {
          margin-top: 20px;
          margin-bottom: 10px;
        }

        &.forgot-pass {
          margin-bottom: 20px;

          &.submitted {
            margin-top: 40px;
          }

          .login-forgot {
            font-size: 13px;
            text-decoration: none;
            color: $text-color-secondary;
            text-align: center;
            font-weight: 500;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &.contact {
          max-width: 300px;
          text-align: center;
          margin: 0 auto;

          p {
            font-size: 12px;
            opacity: 0.6;

            a {
              margin-left: 3px;
            }
          }
        }
      }

      .version-cont {
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        color: $text-color-secondary;
        opacity: 0.8;
      }
    }
  }
}
