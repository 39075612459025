@import "~styles/variables";

.CustomerStep {

  &-form {

    .form-row {
      display: flex;
      flex-wrap: wrap;

      .form-column {
        margin-right: 40px;
      }
    }

    .order-details {
      .is-customer-manager {
        margin-top: 10px;
      }
    }

    .customer-details {

      h4 {
        margin-bottom: 0;
      }

      .radio-btns {
        display: flex;

        .RadioBtn {
          margin-right: 40px;
        }
      }

      .customer-details-input-fields {

        .existing-customer-selector {
          margin-top: 20px;

          .Selector {
            max-width: 350px;
          }
        }

        .new-customer-form {

          .form-row {
            display: flex;
    
            .form-column {
              margin: 0 20px 0 0;
    
              input {
                max-width: 200px;
                min-width: 200px;
              }
    
              &.wide {
    
                input {
                  max-width: 310px;
                  min-width: 310px;
                }
              }
    
              &.radio-btns {
    
                input {
                  min-width: unset;
                }
              }

              .PhoneNumberInput {
                input {
                  max-width: 152px;
                  min-width: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  &-actions {
    margin-top: 30px;
  }
}