@import "~styles/variables";

$option-selected-background: lighten($blue, 30%);

.Selector {
  max-width: 400px;
  height: $form-element-height;
  font-size: 14px;
  position: relative;

  .selector-inner__control {
    border-color: $form-element-border;
    cursor: pointer;
    border-radius: 0;
    min-height: calc(#{$form-element-height} + 2px);

    &:hover {
      border-color: $form-element-border-focus;
    }

    &.selector-inner__control--is-focused {
      border-color: $form-element-border-focus;
      box-shadow: none;
    }

    .selector-inner__multi-value {
      background-color: $option-selected-background;
    }

    .selector-inner__indicators {

      .selector-inner__clear-indicator {
        // display: none;
      }

      .selector-inner__indicator-separator {
        display: none;
      }

      .selector-inner__indicator {
        // &::after {
        //   content: "";
        //   display: block;
        //   border-top: 5px solid $text-color-primary;
        //   border-left: 5px solid transparent;
        //   border-right: 5px solid transparent;
        // }

        svg {
          // display: none;
          color: #464854;
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  .selector-inner__menu {
    margin-top: 0;
    border-radius: 0;
    border: 1px solid $form-element-border-focus;
    box-shadow: none;
    z-index: 3;

    .selector-inner__option {
      color: $text-color-primary;
      cursor: pointer;
      background-color: transparent;
      
      // &.selector-inner__option--is-selected {
      //   background-color: $option-selected-background;
      // }

      &.selector-inner__option--is-disabled {
        cursor: not-allowed;

        &:hover {
          background: transparent;
        }
      }
    }
  }
}
