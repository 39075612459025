@import '~styles/variables';
@import '~styles/helpers';

.NotFound-container {
  @include flexMiddleCenter;
  flex-direction: column;
  padding: 50px 0;
  margin-top: $Header-height;

    .Subtitle {
      margin-top: 10px;
    }

    p {
      text-align: center;
      color: $text-color-primary;
    }
}