@import "~styles/variables";

.VerifyOrderStep {

  .section-header {
    font-weight: 600;
    margin-bottom: 10px;
    color: $text-color-primary;
    font-size: 16px;

    &.order-id,
    &.reference {

      span {
        margin-left: 5px;
      }
    }

    &.reference {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  &-customer {
    margin-bottom: 40px;
  }

  &-products {

  }

  &-actions {

    .Button {
      margin-right: 40px;
    }
  }
}