@import "~styles/variables";

.SelectProductForm {

  .DescriptionTable {
    width: 100%;
  }

  .confirm-btn {
    .Button {
      margin-bottom: 20px;
      margin-top: 0;
    }
  }

  .missing-products-alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50px 0;

    .text {
      margin-top: 10px;
      max-width: 300px;
      text-align: center;
      font-weight: 500;
    }
  }

  .react-tabs__tab {
    font-size: 14px;
    padding: 6px 30px;
  }

  &-main {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;

    .sections-wrapper {
      width: 100%;

      &:first-of-type {
        margin-right: 10%;
      }

      .section-row {
        margin-bottom: 6px;

        .NumberInput {
          input {
            max-width: 400px;
          }
        }
      }
    }

    section {
      margin-bottom: 30px;

      .section-header {
        font-weight: 600;
        margin-bottom: 20px;
        color: $text-color-primary;
        font-size: 18px;

        &.first {
          margin-top: 0;
        }
      }

      .section-row {

        .InputErrorMessage {
          max-width: 400px;
        }

        .TextInput,
        .NumberInput {

          input {
            max-width: 400px;
          }
        }

        .Checkbox {
          margin-top: 10px;
        }

        &.initial-checkbox {
          .Checkbox {
            margin-top: 0;
          }
        }

        &.row-expanded {
          margin-bottom: 10px;

          .Label {
            margin-top: 6px;
            margin-bottom: 10px;
          }

          .radio-btns {

            &.radio-btns-overages {
              margin-top: -5px;
            }

            .RadioBtn {
              margin-top: 14px;
              margin-bottom: 6px;

              &:first-of-type {
                margin-top: 12px;
              }

              label {
                font-weight: 500;
                font-size: 13px;
                color: #525462;
              }
            }
          }

          &.time-limited-row,
          &.subscription-expanded {
            margin-bottom: 10px;
          }

          &.expanded-consumption-row {

            .RadioBtn {
              margin-top: 6px;
              margin-bottom: 6px;
            }
          }

          .DateInput {
            max-width: 400px;
            min-width: unset;
            width: 100%;

            input {
              max-width: unset;
              min-width: unset;
            }
          }

          .Notice {
            max-width: 380px;
          }
        }

        &.row-max-license-users-checkbox {
          .Checkbox {
            margin-top: 4px;
            margin-bottom: 10px;
          }
        }

        .flex-row {
          display: flex;
          flex-wrap: wrap;
          max-width: 400px;

          @media screen and(max-width: 1200px) {
            align-items: flex-start;
          }

          .NumberInput {
            flex-grow: 1;
            margin-right: 10px;

            @media screen and(max-width: 1200px) {
              max-width: unset;
              width: 50%;
              margin-right: 0;
            }

            input {
              min-width: unset !important;
              margin-right: 10px;

              @media screen and(max-width: 1200px) {
                width: 95% !important;
                margin-right: 0;
              }
            }
          }

          .Selector {
            flex-grow: 1;

            @media screen and(max-width: 1200px) {
              max-width: unset;
              width: 50%;
            }

            .selector-inner__control {
              min-height: calc(32px + 2px);
            }
          }
        }
      }
    }
  }
}