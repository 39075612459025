@import "~styles/variables";
@import "~styles/helpers";

.ManagerLicenseList {

  &-licensetypes {
    margin-top: 0;
    margin-bottom: 50px;
    font-weight: 500;
    font-size: 14px;

    .Selector {
      max-width: 250px;

      .selector-inner__control {
        border: none;
        border-bottom: 1px solid #e3e3e3;
        background-color: transparent;
      }
    }
  }
}