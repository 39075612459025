@import '~styles/variables';
@import '~styles/helpers';

.Sidebar {
  background-color: $white;
  width: 200px;
  padding: 10px 20px;
  border-right: 1px solid hsla(0,0%,43.9%,.2);
  flex-shrink: 0;
  min-height: calc(100vh - #{$Header-height} - 20px);

  &-inner {
    display: flex;
    flex-direction: column;
    margin: 0 0 40px 0;
    position: fixed;
  }

  &-version {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 220px;
    padding: 10px;
    color: $text-color-secondary;
    font-size: 11px;
    text-align: center;
    background-color: #f5f5f5;
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.08);

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    img {
      margin-right: 10px;
    }
  }
}