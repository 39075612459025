@import "~styles/variables";
@import "~styles/helpers";

.ProductDownloadTable {

  .headings {
    font-weight: 500;
    margin: 30px 0 10px;
  }

  .table {

    .DescriptionTable {

      tr {
        vertical-align: top;
      }
    }
  }
}