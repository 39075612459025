@import "~styles/variables";
@import "~styles/helpers";
.ProductDownloadItem {
  .row {
    margin: 2px;
    span {
      font-size: 12px;
      padding-right: 5px;
    }
  }
  .download-link {
    margin: 10px 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
    color: #ffffff;
    text-align: center;
    color: #fff;
    outline: 0;
    -webkit-transition: 150ms all ease-in-out;
    transition: 150ms all ease-in-out;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    height: 24px;
    padding: 0 20px;
    background: #2e86de;
    border: 1px solid #1f72c4;
    text-decoration: none;
  }
}