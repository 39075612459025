.ChangePasswordForm {

	form {
		display: flex;

		@media screen and (max-width: 1100px) {
			flex-direction: column;
		  }

		.DescriptionCard {
			margin: 20px 0 0 0;
			width: 45%;
			padding: 10px 10px 0 10px;
			align-items: flex-start;

			@media screen and (max-width: 1100px) {
				width: 95%;
			  }
		}

		.ChangePassword-fields {
			margin: 0 auto;

			.Label {

				&:first-of-type {
					margin-top: 0;
				}
			}

			@media screen and (max-width: 1100px) {
				margin: 20px 0 0 0;
				width: 100%;
			  }
		}
	}
}