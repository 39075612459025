@import '~styles/variables';

$text-error: darken($red, 25%);

.Notice {
  font-family: $font-family;
  color: $text-color-primary;
  border-width: 1px;
  border-style: solid;
  // max-width: 1280px;
  margin: 10px 0;

  .Notice-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;
  }
  
  .Notice-body {
    font-size: 14px;
    color: $text-color-secondary;
  }

  &-size--lg {
    padding: 30px;
  }

  &-size--md {
    padding: 20px;
  }

  &-size--sm {
    padding: 10px;
  }

  &-theme--success {
    background: rgba($green, .25);
    border-color: rgba($green, .35);

    .Notice-title {
      color: darken($green, 25%);
    }

    .Notice-body {
      color: darken($green, 15%);
    }
  }

  &-theme--error {
    background: rgba($red, .25);
    border-color: rgba($red, .35);

    .Notice-title {
      color: $text-error;
    }

    .Notice-body {
      color: rgba($text-error, .8);
    }
  }

  &-theme--warning {
    background: rgba($yellow, .25);
    border-color: rgba($yellow, .35);

    .Notice-title {
      color: darken($yellow, 30%);
    }

    .Notice-body {
      color: darken($yellow, 25%);
    }
  }

  &-theme--info {
    background: rgba($blue, .25);
    border-color: rgba($blue, .35);

    .Notice-title {
      color: darken($blue, 25%);
    }

    .Notice-body {
      color: darken($blue, 15%);
    }
  }

  .actionable-notice {
    display: flex;
    flex-direction: column;

    .text {
      
    }

    .actions {
      display: flex;

      .Button {
        margin: 10px 0 0;
      }
    }
  }
}