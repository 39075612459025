.ProductFeatures {

    &-activity {
        margin-top: 50px;
    }

    .floating-slots-btn {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        span {
            margin-right: 10px;
        }
    }
}