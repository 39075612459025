@import '~styles/variables';

.NavLink {
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  color: $text-color-primary;

  header {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin: 10px 0 0;

    .NavLink-icon {
      display: flex;
      align-items: center;
      width: 30px;
      margin-right: 10px;
    }
  }

  &:hover {
    color: #111;
  }

  &-active {
    color: $blue;

    &:hover {
      color: $blue;
    }

    .NavLink-submenu {
      display: block;
    }

    svg {
      fill: $blue;
    }

    .CompanyConfig {

      svg {
        fill: none;
        stroke: $blue;
      }
    }
  }
}