.HiddenPasswordField {

  span {
    margin-right: auto;
    padding-right: 5px;
    font-size: 16px;
  }

  .clipboard-btn {
    display: inline-flex;
    align-items: center;
    z-index: 9;
    background-color: transparent;
    opacity: 0.5;
    -webkit-appearance: none;
    border: none;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: #e2e2e2;
      opacity: 1;
    }
  }
}