@import '~styles/helpers';
@import '~styles/variables';

.SearchForm {
  display: flex;

  form {
    display: flex;
    align-items: center;
    position: relative;

    button[type=submit] {
      @include appearance(none);
      @include flexMiddleCenter;
      border: 0;
      border-radius: 0 3px 3px 0;
      margin: 0;
      padding: 0 20px;
      position: relative;
      height: $form-element-height;
      cursor: pointer;
      outline: 0;
      z-index: 2;
    }

    .TextInput {

      input {
        padding: 0 10px 0 15px;
        width: 250px;
        min-width: 350px;
      }
      .InputErrorMessage {
        display: none;
      }
    }

    button[type=button] {
      @include appearance(none);
      @include flexMiddleCenter;
      cursor: pointer;
      position: absolute;
      right: 70px;
      // margin-left: -30px;
      width: 40px;
      height: $form-element-height;
      font-size: 20px;
      text-align: center;
      border: 0;
      background: transparent;
      outline: 0;
      opacity: 0.5;
      z-index: 1;

      &:hover {
        opacity: 1;
      }
    }
  }

  .Selector {
    width: 200px;
    font-size: 12px;
    font-weight: 500;

    .selector-inner__control {
      min-height: 34px;
    }
  }
}