@import "~styles/variables";

.ActionMenu {
  position: relative;

  .Dropdown {
    top: 32px;
    right: 20px;
    padding: 0;

    button {
      text-align: start;
      width: 100%;
      background-color: transparent;
    }

    .li-content {
      cursor: pointer;
      outline: none;
      padding: 10px 15px !important;
  
      &:hover {
        background-color: $option-hover-background;
      }
    }
  }
}